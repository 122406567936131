










































import { Component, Vue, Prop } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Can from '@/shared/components/Can.vue'
import permissions from '@/shared/helpers/permissions.helper'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import ToolService from '@/shared/modules/tool/tool.service'
import { GlobalActions } from '@/shared/store/global/global.actions'
import ToolHistoryDetail from '@/company/components/tools/ToolHistoryDetail.vue'

@Component({
    components: { Can, DataTable, PageHeader },
    methods: { __ }
  })
  export default class ToolHistory extends Vue {
    @Prop() uuid!: string

    permissions = permissions

    history: object[]|null = null

    async created() {
      this.history = (await ToolService.history(this.company, this.uuid)).data;
    }

    openDetails(entry: any) {
      this.$store.dispatch(GlobalActions.showDialog, {
        show: true,
        maxWidth: 670,
        component: ToolHistoryDetail,
        meta: {
          entry,
        }
      })
    }

    get company(): Company {
      return this.$store.getters[CompanyGetters.getCompany]
    }

    get user(): User {
      return this.$store.getters[AuthGetters.getUser]
    }
  }
