



























import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import { CompanyRoutes } from '@/shared/router/company'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import Tool from '@/shared/modules/tool/models/tool.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import permissions from '@/shared/helpers/permissions.helper'
import ToolHistory from '@/company/views/tools/ToolHistory.vue'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import { RoleLevel } from '@/shared/modules/role/role.model'
import FormGroup from '@/shared/classes/form/group'
import ListToolPhotos from '@/company/components/tools/ListToolPhotos.vue'
import can from '@/shared/helpers/can.helper'
import ToolService from '@/shared/modules/tool/tool.service'

@Component({
  components: { PageHeader, Form, ToolHistory },
  methods: { __, can }
})
export default class ToolsShow extends Vue {
  permissions = permissions
  form: FormBase = null!
  tool: Tool|null = null

  static GROUP_MAIN = 'main'
  static GROUP_ATTACHMENTS = 'attachments'

  created() {
    this.form = new FormBase()
      .setEndpoint(`company/${ this.company.slug }/tools`)
      .setModel(Tool)
      .setUuid(this.$router.currentRoute.params.uuid)
      .setOnSetEntry((tool: any) => this.tool = tool)
      .setInjectValues({
        company_uuid: this.company.uuid
      })
      .setGroups([
        new FormGroup()
          .setKey(ToolsShow.GROUP_MAIN),
        new FormGroup()
          .setKey(ToolsShow.GROUP_ATTACHMENTS)
          .setComponent(ListToolPhotos),
      ])
      .setFields([
        new Field()
          .setKey('internal_no')
          .setTitle(__('company.views.tools.form.details.internal_no'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setDisabled(true),
        new Field()
          .setKey('name')
          .setTitle(__('company.views.tools.form.details.name'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setDisabled(true),
        new SearchableField()
          .setKey('category_uuid')
          .setTitle(__('company.views.tools.form.details.category'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .loadItems({ endpoint: `/company/${ this.company.slug }/tool-categories`, value: 'uuid', title: 'name', perPage: 20 })
          .setDisabled(true),
        new SearchableField()
          .setKey('location_uuid')
          .setTitle(__('company.views.tools.form.details.location'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .loadItems({ endpoint: `/company/${ this.company.slug }/tool-locations`, value: 'uuid', title: 'name', perPage: 20 })
          .setDisabled(true),
        new SearchableField()
          .setKey('retrieved_by_user_uuid')
          .setTitle(__('company.views.tools.form.details.retrieved_by'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setDisabled(true)
          .loadItems({
            endpoint: `/company/${ this.company.slug }/employees`,
            value: 'uuid',
            title: 'full_name',
            perPage: 20,
            filters: [
              {
                type: FilterOperators.equals,
                name: 'role.level',
                value: RoleLevel.employee,
              }
            ]
          })
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('retrieved_date')
          .setTitle(__('company.views.tools.form.details.retrieved_date'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setSize(FieldSizes.half)
          .setDisabled(true),
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('purchased_date')
          .setTitle(__('company.views.tools.form.details.purchased_date'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setSize(FieldSizes.half)
          .setDisabled(true),
        new Field()
          .setKey('purchased_place')
          .setTitle(__('company.views.tools.form.details.purchased_place'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setSize(FieldSizes.half)
          .setDisabled(true),
        new Field()
          .setKey('manufacturer')
          .setTitle(__('company.views.tools.form.details.manufacturer'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setDisabled(true),
        new Field()
          .setType(FieldTypes.number)
          .setKey('price')
          .setTitle(__('company.views.tools.form.details.price'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setSize(FieldSizes.fourTwelfth)
          .setDisabled(true),
        new Field()
          .setType(FieldTypes.number)
          .setKey('rental_internal_price')
          .setTitle(__('company.views.tools.form.details.rental_internal_price'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setSize(FieldSizes.fourTwelfth)
          .setDisabled(true),
        new Field()
          .setType(FieldTypes.number)
          .setKey('rental_external_price')
          .setTitle(__('company.views.tools.form.details.rental_external_price'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setSize(FieldSizes.fourTwelfth)
          .setDisabled(true),

        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('fixed_location')
          .setTitle(__('company.views.tools.form.details.fixed_location'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setSize(FieldSizes.half)
          .setVisibleIf((values) => this.user.hasDynamicPermissions([permissions.company.tools.store, permissions.company.tools.update]))
          .setDisabled(true),
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('expected_finished')
          .setTitle(__('company.views.tools.form.details.expected_finished'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setSize(FieldSizes.half)
          .setDisabled(true),
        new Field()
          .setKey('link')
          .setTitle(__('company.views.tools.form.details.link'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setDisabled(true),
        new Field()
          .setType(FieldTypes.textArea)
          .setKey('comment')
          .setTitle(__('company.views.tools.form.details.comment'))
          .setGroup(ToolsShow.GROUP_MAIN)
          .setDisabled(true)
      ])
    .setSubmit(false)
  }

  moveTool(): void {
    if (! this.tool) return;
    this.$router.push({ name: CompanyRoutes.toolsMove, params: { tools: [this.tool.uuid].join(',') } })
  }

  discardTool(): void {
    if (! this.tool) return;
    ToolService.discard(this.company.slug, this.tool.uuid)
      .then(() => this.$router.push({ name: CompanyRoutes.toolsDiscarded }))
  }

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.toolsIndex })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
