import Model from '@/shared/classes/model'
import ToolCategory from '@/shared/modules/tool-category/models/tool-category.model'
import ToolLocation from '@/shared/modules/tool-location/models/tool-location.model'
import Company from '@/shared/modules/company/company.model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import IToolResponse from '@/shared/modules/tool/interfaces/tool-response.interface'
import Attachment from '@/shared/modules/attachment/attachment.model'
import User from '@/shared/modules/user/user.model'

export default class Tool extends Model<IModelResponse> {
  static ALIAS: string = 'tool'

  uuid!: string
  category_uuid!: string
  location_uuid!: string

  internal_no!: string
  name!: string

  retrieved_by_user_uuid!: string
  retrieved_date!: string

  purchased_date!: string
  purchased_place!: string
  manufacturer!: string

  price!: number
  rental_internal_price!: number
  rental_external_price!: number

  fixed_location!: boolean
  expected_finished!: string
  link!: string
  comment!: string

  discarded_at!: string
  discarded_by_user_uuid!: string

  attachmentsCount!: number
  lastAttachment!: string|null

  photos!: Attachment[]
  category!: ToolCategory
  location!: ToolLocation
  company!: Company
  discardedBy!: User
  retrievedBy!: User


  transform(data: IToolResponse): void {
    super.transform(data)

    this.uuid = data.uuid
    this.category_uuid = data.category_uuid
    this.location_uuid = data.location_uuid

    this.internal_no = data.internal_no
    this.name = data.name

    this.retrieved_by_user_uuid = data.retrieved_by_user_uuid
    this.retrieved_date = data.retrieved_date
    this.purchased_date = data.purchased_date
    this.purchased_place = data.purchased_place
    this.manufacturer = data.manufacturer

    this.price = data.price
    this.rental_internal_price = data.rental_internal_price
    this.rental_external_price = data.rental_external_price

    this.fixed_location = data.fixed_location
    this.expected_finished = data.expected_finished
    this.link = data.link
    this.comment = data.comment

    this.discarded_at = data.discarded_at
    this.discarded_by_user_uuid = data.discarded_by_user_uuid

    this.attachmentsCount = data.attachments_count
    this.lastAttachment = data.last_attachment

    this.photos = this.setRelation(Attachment, data.photos)
    this.category = this.setRelation(ToolCategory, data.category)
    this.location = this.setRelation(ToolLocation, data.location)
    this.company = this.setRelation(Company, data.company)
    this.discardedBy = this.setRelation(User, data.discarded_by)
    this.retrievedBy = this.setRelation(User, data.retrieved_by)
  }
}
