import Model from '@/shared/classes/model'
import Company from '@/shared/modules/company/company.model'
import IToolLocationResponse from '@/shared/modules/tool-location/interfaces/tool-location-response.interface'

export default class ToolLocation extends Model<IToolLocationResponse> {
  static ALIAS: string = 'tool-location'

  uuid!: string
  name!: string
  deactivated_at!: string

  quantity!: number

  company_uuid!: string
  company!: Company

  transform(data: IToolLocationResponse): void {
    super.transform(data)

    this.name = data.name
    this.deactivated_at = data.deactivated_at
    this.quantity = data.quantity
    this.company_uuid = data.company_uuid
    
    this.company = this.setRelation(Company, data.company)
  }
}
