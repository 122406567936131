



import { Component, Prop, Vue } from 'vue-property-decorator'
import AttachmentsPreview from '@/company/components/attachments/AttachmentsPreview.vue'
import FormBase from '../../../shared/classes/form/form-base'
import Attachment from '../../../shared/modules/attachment/attachment.model'

@Component({
  components: {
    AttachmentsPreview
  }
})
export default class ListToolPhotos extends Vue {
  @Prop() form!: FormBase
  attachments: Attachment[] = []

  async created() {
    this.attachments = this.form.entry.photos
  }
}
