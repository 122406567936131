import Service from '@/shared/classes/service'
import Company from '@/shared/modules/company/company.model'
import Tool from '@/shared/modules/tool/models/tool.model'
import Attachment from '@/shared/modules/attachment/attachment.model'
import IAttachmentResponse from '@/shared/modules/attachment/attachment-response.interface'
import IResponse from '@/shared/interfaces/modules/response.interface'
import IToolResponse from '@/shared/modules/tool/interfaces/tool-response.interface'
import PendingToolMove from '@/shared/modules/pending-tool-move/pending-tool-move.model'

export enum GenerateType {
  xls = 'xls',
  pdf = 'pdf'
}

export default class ToolService extends Service {
  static prefix: string = 'tools'

  static history(company: Company, toolUuid: string): Promise<any> {
    return this.put(`company/${ company.slug }/${ this.prefix }/${ toolUuid }/history`, {})
  }

  static discard(companySlug: string, toolUuid: string): Promise<Tool> {
    return this.put(`company/${ companySlug }/${ this.prefix }/${ toolUuid }/discard`, {})
      .then((response: IResponse<IToolResponse>) => new Tool(response.data))
  }

  static uploadAttachments(company: Company, toolUuid: string, data: FormData): Promise<any> {
    return this.post(`company/${ company.slug }/${ this.prefix }/${ toolUuid }/upload`, data)
      .then((response) => response.data)
  }

  static deleteAttachment(company: Company, tool: Tool, attachment: Attachment): Promise<any> {
    return this.delete(`company/${ company.slug }/${ this.prefix }/${ tool.uuid }/attachments/${ attachment.uuid }`)
  }

  static attachments(company: Company, tool: Tool): Promise<Attachment[]> {
    return this.get(`company/${ company.slug }/${ this.prefix }/${ tool.uuid }/attachments`)
      .then((response: IResponse<IAttachmentResponse>) => response.data.data.map((item: IAttachmentResponse) => new Attachment(item)))
  }

  static acceptPendingMoves(company: Company, data: any): Promise<any> {
    return this.put(`company/${ company.slug }/${ this.prefix }/pending-tool-moves`, {
      type: 'accept',
      data,
    })
  }

  static declinePendingMoves(company: Company, data: any): Promise<any> {
    return this.put(`company/${ company.slug }/${ this.prefix }/pending-tool-moves`, {
      type: 'decline',
      data,
    })
  }

  static export(company: Company, type: GenerateType, location: string|null, category: string|null, query: string|null, isDiscarded: boolean = false): Promise<any> {
    return this.get(`company/${ company.slug }/${this.prefix}/export?type=${ type }&filter-equals-category_uuid=${ category }&filter-equals-location_uuid=${ location }&q=${ query }&filter-nulled-discarded_at=${!isDiscarded}`, {
      responseType: 'blob',
    })
  }

  static discardedPrice(company: Company, isDiscarded: boolean, discarded_year: string|null, location: string, category: string, q: string): Promise<any> {
    return this.get(`company/${ company.slug }/${this.prefix}/discarded-price?filter-nulled-discarded_at=${!isDiscarded}&filter-equals-category_uuid=${ category }&filter-equals-location_uuid=${ location }&q=${ q }${discarded_year ? `&filter-equalsYear-discarded_at=${discarded_year}` : ''}`)
      .then((response) => response.data)
  }
}
